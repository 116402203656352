import { useRouteError } from "react-router-dom";

import React from 'react'

 const ErrorPage = () => {

    const error = useRouteError();


  return (
    <div className="error-page">
        <h1>
        Oops!
        </h1>
        <p>
            Sorry, an unexpecyted error has occurred!
        </p>
        <p>
            <i>
                {error.statusText || error.message}
            </i>
        </p>
    </div>
  )
}

export default ErrorPage;