import React, {useState} from "react";
import { Link } from "react-router-dom";
import Icon from "../Tags/Icon";
import { routes } from "../../Routes/Root";
import { useDispatch } from "react-redux";
import {lognOutUser} from "../../App/features/user/userSlice";
import { useNavigate } from 'react-router';

export const SideBar = () => {
  const [styled, setStyled] = useState('overview')
  const navigate = useNavigate();

  const dispatch = useDispatch()

   const select = (name, route) => {
    setStyled(name)
    navigate(route)
   }
  return (
    <aside className="aside">
      <fieldset className="icon-holder">
        <Icon url={"/images/birdies.svg"} />
      </fieldset>
      <nav className="nav">
        <ul className="unordered-lists">
          <li onClick={() => select('overview', '/dashboard')} className={`list-itemA ${ styled == 'overview' ? 'list-itemAB' : ''}`}tabIndex={0}>
            <Icon url={"/images/calendar.svg"} />
            <div className="list-item" >
              Overview
            </div>
          </li>
          <li onClick={() => select('products', 'products')} className={`list-itemA ${ styled == 'products' ? 'list-itemAB' : ''}`} tabIndex={0}>
            <Icon url={"/images/money-send.svg"} />
            <div className="list-item" >
              Products
            </div>
          </li>
          <li onClick={() => select('orders', 'order')} className={`list-itemA ${ styled == 'orders' ? 'list-itemAB' : ''}`}  tabIndex={0}>
            <Icon url={"/images/frame.svg"} />
            <Link className="list-item" >
              Order
            </Link>
          </li>
          <li onClick={() => select('locations', 'locations')} className={`list-itemA ${ styled == 'locations' ? 'list-itemAB' : ''}`} tabIndex={0}>
            <Icon url={"/images/location.svg"} />
            <div className="list-item" >
              Locations
            </div>
          </li>
          <li onClick={() => select('customers', 'customers')} className={`list-itemA ${ styled == 'customers' ? 'list-itemAB' : ''}`}  tabIndex={0}>
            <Icon url={"/images/wallet-remove.svg"} customClasses='icon'/>
            <div className="list-item"  >
              Customers
            </div>
          </li>
          <li onClick={() => select('promotion', 'promotion')} className={`list-itemA ${ styled == 'promotion' ? 'list-itemAB' : ''}`}  tabIndex={0}>
            <Icon url={"/images/mobile-programming.svg"} />
            <div className="list-item" >
              Promotion
            </div>
          </li>
          <li onClick={() => select('settings', 'settings')} className={`list-itemA ${ styled == 'settings' ? 'list-itemAB' : ''}`}  tabIndex={0}>
            <Icon url={"/images/mobile-programming.svg"} />
            <div className="list-item">
              Settings
            </div>
          </li>        
        </ul>
        <fieldset onClick={() => dispatch(lognOutUser())} className='field-below' tabIndex={0}>
            <Icon url={"/images/logout.svg"} />
            <div className="field-link"  to="#">
              Logout
            </div>
        </fieldset>
      </nav>
    </aside>
  );
};
