import React, { useEffect, useState } from "react";
import { Col, Row, Dropdown, Space, List, Input, message,Tag, Table, notification} from "antd";
import Icon from "../../components/Tags/Icon";
import HeaderThree from "../../components/Tags/Headers/HeaderThree";
import { SearchOutlined } from "@ant-design/icons";
import HeaderSix from "../../components/Tags/Headers/HeaderSix";
import AxiosCall from "../../App/config/axios";

const onSearch = (value, _e, info) => console.log(info?.source, value);

const Order = () => {
  const [orders, setOrders] = useState({docs: []})
  const [neworders, setnewOrders] = useState([])
  const [loading, setLoading] = useState(false);

console.log(neworders.map((text) => text.productId
));
console.log(neworders);

  useEffect(() => {
    getOrders()
  }, []);

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };

  const getOrders = async (page = 1) => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "order",
        method: "GET",
        params: {page: page, limit: 10},
      });
      notificationWithIcon("success", "all orders", response.message);
      setLoading(false);
      setOrders(response.data);
      var newArr = []
      response.data.docs.map(doc => {newArr = [...newArr, ...doc.items]})
      setnewOrders(newArr)
    } catch (error) {
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };


  const columns = [
    {
      title: "UPC",
      dataIndex: "productId",
      width: 160,
      render:(productId) => (<>
        {productId.upc}
      </>)
    },
    {
      title: "Product Name",
      dataIndex: "productId",
      width: 220,
      render:(productId) => (<>
        {productId.name}
      </>)
    

    },
    {
      title: "Price",
      dataIndex: "productId",
      width: 100,
      render:(productId) => (<>
        ${productId.price}
      </>)


    },
    {
      title: "Points",
      dataIndex: "points",
      width: 50,
    }
  ];

  const data = [];
  for (let i = 0; i < 100; i++) {
    data.push({
      key: i,
      ash: `${i + 1}`,
      id: '#348923',
      name: "Topping Pizza",
      price: "$26.00",
      date:'20/02/2024',
      status:<span className="tags"><Tag className="tag-a">Completed</Tag><Tag className="tag-b">Pending</Tag><Tag className="tag-c">Failed</Tag></span>,
      point: (
        <span className="point">
          <Icon url="/images/Group 1000004938.svg" />
          <HeaderSix text="250 Point" />
        </span>
      ),
    });
  }

  return (
    <Row className="order">
      <Col>
        <div className="order-top">
          <div className="order-state">
            <HeaderSix text="All orders" customClasses='state on-hover' tabIndex={0}/>
            <HeaderSix text="Conpleted" customClasses='state on-hover' tabIndex={0}/>
            <HeaderSix text="Pending" customClasses='state on-hover' tabIndex={0}/>
            <HeaderSix text="Cancels" customClasses='state on-hover' tabIndex={0}/>
          </div>
          <div className="order-sort">
            <Input
              prefix={<SearchOutlined onClick={{ onSearch }} />}
              placeholder="Search"
              className="order-search"
            />
            <fieldset className="filter">
              <Icon url="/images/icon.svg" customClasses='state'/>
              <HeaderSix text="Filter" customClasses='stater'/>
            </fieldset>
            <fieldset className="sort">
              <Icon url="/images/chevron-v.svg" customClasses='state'/>
              <HeaderSix text="Sort" customClasses='stater'/>
            </fieldset>
          </div>
        </div>
      </Col>
      <Col>
      <Table
          columns={columns}
          dataSource={neworders}
          pagination={{
            onChange: (page) => {
              getOrders(page)
              console.log(page);
            },
            pageSize:10,
            total:orders.totalDocs,
          }}
          loading={loading}
          className="order-table"
          size="small"
          bordered={false}
        />
      </Col>
    </Row>
  );
};
export default Order;
