import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    user: null,
    isLoggedIn: false,
    token: null,
    notifications: []
}
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        persistUserData: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logInUser: (state, action) => {
            console.log(action.payload);
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.isLoggedIn = true;
            localStorage.setItem('token', action.payload.token)           
          },
          lognOutUser: state => {
            state.user = null
            state.token = null
            state.isLoggedIn = false
            localStorage.clear()
            window.location.assign('/login')
          },
          updateUserAndNotifications: (state, action) => {
            state.user = action.payload.user
            state.notifications = action.payload.notifications
          }
    }
  })
  
  export const { persistUserData ,logInUser, lognOutUser, updateUserAndNotifications } = userSlice.actions
  
  export default userSlice.reducer