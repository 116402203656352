import React, { useEffect, useState } from "react";
import { Row, Col, Input, Card, List, notification } from "antd";
import Icon from "../../components/Tags/Icon";
import HeaderThree from "../../components/Tags/Headers/HeaderThree";
import { SearchOutlined } from "@ant-design/icons";
import HeaderSix from "../../components/Tags/Headers/HeaderSix";
import { Link } from "react-router-dom";
import AxiosCall from "../../App/config/axios";
import { useParams, useNavigate } from "react-router-dom";


const { Meta } = Card;

const onSearch = (value, _e, info) => console.log(info?.source, value);

const SubTextProducts = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({docs: []});
  const { id } = useParams();

console.log(id);
  useEffect(() => {
    getTagSubs();
  }, []);

 

  const handleProductDelete = async (productId) => {
    const confirm = window.confirm("would you like to delete?");
    if (confirm) {
      setLoading(true);
      try {
        const response = await AxiosCall({
          path: "item",
          method: "DELETE",
          data: { productId },
        });
        console.log(response);
        notificationWithIcon("success", "product deletion", response.message);
        getTagSubs()
        setLoading(false);
      } catch (error) {
        let errorResponse = "Something went wrong. please try again";
        if (error?.response) {
          const { err } = error.response.data;
          errorResponse = err;
        }
        notificationWithIcon(
          "error",
          "Error",
          errorResponse || "Something went wrong"
        );
      }
    }
  };

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };

  const getTagSubs = async (page = 1) => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "item",
        method: "GET",
        params: {
            page: page,
            limit: 8,
            tagId: id
        }
      });
      const data = response;
      notificationWithIcon("success", "All Sub-products", response.message);
      setLoading(false);
      console.log(response);
      setProducts(response.data);
      return data
    } catch (error) {
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  }; 

  return (
    <Row className="subproducts">
      <Col>
        <div className="top-pane">
          <div className="counter">
            <fieldset className="product-count">
              <HeaderThree text="Products:" customClasses="sub" />
              <span className="state">{products?.totalDocs || 0}</span>
            </fieldset>
            <Input
              prefix={<SearchOutlined onClick={{ onSearch }} />}
              placeholder="Search here..."
              className="nav-search"
            />
            <fieldse className="filter">
              <Icon url="/images/icon.svg" customClasses="state" />
              <HeaderSix text="Filter" customClasses="state" />
            </fieldse>
          </div>
          <div className="plus">
            <fieldset className="highlight">
              <Icon url="/images/Container.svg" customClasses="icon-a" />
              <Icon url="/images/Container2.svg" customClasses="icon" />
            </fieldset>
            <Link className="edit" to="/dashboard/AddProduct">
              <HeaderThree text="Edit Subtext" customClasses="sub" />
            </Link>
          </div>
        </div>
      </Col>
      <Col className="product-list">
        <List
          className="flewRap"
          grid={{
            gutter: 24,
            column: 4,
          }}
          itemLayout="vertical"
          loading={loading}
          pagination={{
            onChange: (page) => {
            getTagSubs(page)
              console.log(page);
            },
            pageSize:8,
            total:products.totalPages
          }}
          dataSource={products.docs}
          renderItem={(product) => (
            <List.Item key={product._id}>
              <Card
                hoverable
                size="small"
                cover={product.images.map((image, dataIndex) => (
                  <img
                    key={dataIndex}
                    alt="example"
                    src={image}
                    className="card-image"
                  />
                ))}
                className="card"
                key={product._id}
              >
                <Meta title={product.name} className="meta" />
                <fieldset className="edit">
                  <p className="price">{"$" + product.price}</p>
                  <span style={{ display: "flex", gap: ".5rem" }}>
                    <Link to={`/dashboard/EditProduct/${product._id}`}>
                      <Icon url="/images/edit.svg" />
                    </Link>
                    <Icon
                      url="/images/delete.svg"
                      onClickEvent={() => handleProductDelete(product._id)}
                    />
                  </span>
                </fieldset>
                <div className="availability">
                  <fieldset className="stock">
                    <Icon url="/images/world.svg" />
                    <HeaderSix
                      text={`${product.quantity} in Stock`}
                      customClasses="stock-val"
                    />
                  </fieldset>
                  <fieldset className="stock">
                    <Icon url="/images/Group 1000004938.svg" />
                    <HeaderSix
                      text={`${product.points} points`}
                      customClasses="stock-val"
                    />
                  </fieldset>
                </div>
              </Card>
            </List.Item>
          )}
        />
      </Col>
    </Row>
  );
};
export default SubTextProducts;
