import React, { useEffect, useState } from "react";
import { Row, Col, Input, Table, notification } from "antd";
import Icon from "../../components/Tags/Icon";
import HeaderThree from "../../components/Tags/Headers/HeaderThree";
import HeaderSix from "../../components/Tags/Headers/HeaderSix";
import { SearchOutlined } from "@ant-design/icons";
import AxiosCall from "../../App/config/axios";

const onSearch = (value, _e, info) => console.log(info?.source, value);

const Customers = () => {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState({docs:[]});

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };
  
  useEffect(() => {
    getCustomers()
  }, [])

  const columns = [
    {
      title: "SN",
      dataIndex: "ssa",
      width: 50,
    },
    {
      title: "Name",
      dataIndex: "fullname",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: 150,
    },
    {
      title: "Account",
      dataIndex: "phone",
      width: 150,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      width: 150,
    }, {
      title: "Total Pionts",
      dataIndex: "rewardPoints",
      width: 150,
    },
  ];

  const getCustomers = async ( page = 1 ) => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "users/get-all-users",
        method: "GET",
        params: {
          page:page,
          limit:10
        }
      });
      notificationWithIcon("success", "all customers", response.message);
      setLoading(false);
      setCustomers(response.data);
      console.log(response.data);
    } catch (error) {
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  return (
    <Row className="customer">
      <Col>
        <div className="customer-top">
          <HeaderThree text="All Customers" customClasses='sub' />
          <div className="customer-sort">
            <Input
              prefix={<SearchOutlined onClick={{ onSearch }} />}
              placeholder="Search"
              className="customer-search"
            />
            <fieldset className="filter">
              <Icon url="/images/icon.svg" customClasses='state' />
              <HeaderSix text="Filter" customClasses='state' />
            </fieldset>
            <fieldset className="sort">
              <Icon url="/images/chevron-v.svg" customClasses='state' />
              <HeaderSix text="Sort" customClasses='state' />
            </fieldset>
          </div>
        </div>
      </Col>
      <Col>
        <Table
          columns={columns}
          dataSource={customers.docs}
          loading={loading}
          pagination={{
            onChange: (page) => {
              getCustomers(page)
            },
            total: customers.totalDocs,
            pageSize: 10,
          }}
          // scroll={{
          //   y: 240,
          // }}
          className="customer-table"
        />
      </Col>
    </Row>
  );
};

export default Customers;
