import React from "react";
import Chart from "react-apexcharts";
import HeaderSix from "../Tags/Headers/HeaderSix";
import { Dropdown, Space} from "antd";
import { DownOutlined } from "@ant-design/icons";


const EarningChart = () => {

    const items = [
        {
          key: "1",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.antgroup.com"
            >
              January
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.aliyun.com"
            >
              February
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              March
            </a>
          ),
          // disabled: false,
        },
        {
          key: "4",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              April
            </a>
          ),
        },
        {
          key: "5",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              May
            </a>
          ),
        },
        {
          key: "6",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              June
            </a>
          ),
        },
        {
          key: "7",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              July
            </a>
          ),
        },
        {
          key: "8",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              August
            </a>
          ),
        },
        {
          key: "9",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              September
            </a>
          ),
        },
        {
          key: "10",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              October
            </a>
          ),
        },
        {
          key: "11",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              November
            </a>
          ),
        },
        {
          key: "12",
          danger: false,
          label: (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.luohanacademy.com"
            >
              December
            </a>
          ),
        },
      ];

  var options = {
    series: [
      {
        name: "amount",
        data: [200, 400, 600, 800, 1000, 700, 500, 1000, 1100, 1000, 1000, 1100],
      },
    ],
    chart: {
      height: 350,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
        labels: {
            formatter: function (value) {
              return value;
            }
          },
      type: "month",
      categories: [
        "Jan.",
        "Feb.",
        "Mar.",
        "Apr.",
        "May",
        "Jun.",
        "Jul.",
        "Aug.",
        "Sep.",
        "Oct.",
        "Nov.",
        "Dec.",
      ],
    },
    tooltip: {
      x: {
        format: "''/MM/''",
      },
    },
    yaxis: {
        labels: {
          formatter: function (value) {
            return "$" + value;
          }
        },
      },
      colors: ["#1AE8CE"],
  };

  return (
    <div className="earning-chart">
       <fieldset className="earningchart-fieldset">
            <HeaderSix
              text="Earning Analytics"
              customClasses="earningchart-header"
            />
            <Dropdown
              menu={{   
                items,
              }}
              className="chart-dropdown"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  Monthly
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </fieldset>
      <Chart height={270} width={725} options={options} series={options.series} type="area"/>
    </div>
  );
};
export default EarningChart;
