import Axios from 'axios'
export const baseURL =  "https://byzpal.com/api"

const AxiosCall = async callObj => {

  const { path, method, data, params, contentType} = callObj;

  let url = `${baseURL}/${path}`;

  const token = localStorage.getItem('token');
 console.log(token);

  const headers = {
    Authorization: token,
    'Content-Type': contentType || 'application/json',
  };
  try {
    const response = await Axios({ url, method, data, params, headers });
    console.log(response);
    const result = response && response.data;
    console.log(result);
    return result;
  } catch (error) {
    if(error?.response?.data?.refresh){
      window.localStorage.clear();
      window.location.assign('/login')
    }
    throw error;
  }
};

 

export default AxiosCall;