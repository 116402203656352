// import App from "../App"
import {createBrowserRouter} from "react-router-dom"
import Overview from "../pages/Overview/Overview"
import Customers from "../pages/Customers/Customers"
import Order from "../pages/Order/Order"
import Products from "../pages/Products/Products"
import Promotion from "../pages/Promotion/Promotion"
import Settings from "../pages/Settings/Settings"
import ErrorPage from "../ErrorPage"
import Login from "../pages/Login/Login"
import AddProduct from "../pages/Products/AddProduct"
import EditProduct from "../pages/Products/EditProduct"
import App from "../App"
import { redirect } from "react-router-dom";
import SubText from "../pages/SubText/SubText"
import SubTextProducts from "../pages/SubText/SubTextProducts"
import Locations from "../pages/Locations/Locations"

const userToken = localStorage.getItem('token')
const ifAuth = () => userToken ? null : redirect('/login')
const ifNotAuth = () => !userToken ? null : redirect('/dashboard')

export const routes = [
  {
    path: "/",
    loader: ifNotAuth,
    element: <Login />
  },
  {
    path: "/dashboard",
    element: <App />,
    errorElement: <ErrorPage />,
    loader: ifAuth,
    children: [
      {
        name:'Overview',
        path: "",
        element: <Overview />
      },
      {
        name:'Customers',
        path: "customers",
        element: <Customers />
      },
      {
        name:'Order',
        path: "order",
        element: <Order />
      },
      {
        name:'Locations',
        path: "locations",
        element: <Locations />
      },
      {
        name:'Products',
        path: "products",
        element:  <Products />
      },
      {
        name:'AddProduct',
        path: "addproduct",
        element: <AddProduct />
      },
      {
        name:'EditProduct',
        path: "editproduct/:id",
        element: <EditProduct />
      },
      {
        name:'Promotion',
        path: "promotion",
        element: <Promotion />
      },
      {
        name:'Settings',
        path: "settings",
        element: <Settings />
      },
      {
        name:'SubText',
        path: "subtext",
        element: <SubText />
      },
      {
        name:'SubTextProducts',
        path: ":id",
        element: <SubTextProducts />
      },
    ],
  },
  {
    path: "login",
    loader: ifNotAuth,
    element: <Login />
  },
];

export const router = createBrowserRouter(routes)