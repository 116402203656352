import React from "react";
import { Col, Row, Input, Flex } from "antd";
import HeaderThree from "../Tags/Headers/HeaderThree";
import { SearchOutlined } from "@ant-design/icons";
import Icon from "../Tags/Icon";
import HeaderOne from "../Tags/Headers/HeaderOne";

const onSearch = (value, _e, info) => console.log(info?.source, value);

const NavBar = ({ children, text }) => {

  return (
    <Flex className="navbar">
      <Col className="nav-col_B">
        <div className="nav-header-div">
        <HeaderOne text={text} />

        </div>
        <div className="nav-sesarch-and-more">
        <Input
          prefix={<SearchOutlined onClick={{ onSearch }} />}
          placeholder="Search"
          className="nav-search"
        />
         <div className="nav-div">
          <div className="nav-notification">
            <Icon url={"/images/notification.svg"} />
          </div>
          <div className="nav-user">
            <fieldset className="nav-fieldset">
              <Icon url={"/images/img.svg"} />
              <div className="img-round-logo"></div>
            </fieldset>
            <HeaderThree
              text="Habeeb AbdulRahman"
              customClasses="nav-user-header"
            />
          </div>
        </div>
        </div>
      </Col>
      <Col className="navbar-children">{children}</Col>
    </Flex>
  );
};

export default NavBar;
