import React from 'react';
import { SideBar } from './components/SideBar/SideBar';
import { Col, Row } from 'antd';
import { Outlet, useLocation} from "react-router-dom";
import NavBar from './components/MainNav/NavBar';
import "antd/dist/reset.css";
import { routes } from './Routes/Root';

function App() {
  const location = useLocation();
  
  return (
    <Row className='App'>
      <Col span={4}>
      <SideBar />
      </Col>
      <Col span={20} id="detail">
      <NavBar children={<Outlet className='outlet' />} text={location.pathname.split("/")[2]} />
      </Col>
    </Row>
  );
}

export default App;
