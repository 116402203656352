import React, { useRef, useState, useEffect } from "react";
import {
  Flex,
  Input,
  Select,
  Form,
  Upload,
  notification,
  Button,
} from "antd";
import Uploader from "../../components/Upload/Uploader";
import AxiosCall from "../../App/config/axios";
import { useNavigate } from 'react-router-dom';

const AddProduct = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(" ");
  const [categories, setCategories] = useState([]);
  const [locations, setLocations] = useState([]);
  const [subtexts, setSubtexts] = useState([]);
  const [images, setImagesUrl] = useState([]);

 
  const navigate = useNavigate()

  useEffect(() => {
    getCategories();
    getLocations();
    getSubtexts()
  }, []);
  console.log(subtexts)
  const form = useRef(null);

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };

  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "category",
        method: "GET",
      });
      setLoading(false);
      setCategories(response.data.docs);
    } catch (error) { 
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const getLocations = async () => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "location",
        method: "GET",
      });
      setLoading(false);
      setLocations(response.data);
    } catch (error) { 
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const getSubtexts = async () => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "tag",
        method: "GET",
      });
      setLoading(false);
      setSubtexts(response.data.docs);
    } catch (error) { 
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const onFinished = async (values) => {
    console.log(images);
    setError("");
    setLoading(true);
    navigate('/dashboard/products')
    try {
      console.log(values);
      const response = await AxiosCall({
        path: "item",
        method: "POST",
        data: { ...values, images },
      });
      notificationWithIcon(
        "success",
        "product successfully added",
        response.message
      );
      setError("");
      setLoading(false);
    } catch (error) { 
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      // setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  console.log(locations)
  return (
    <Form
      ref={form}
      layout="vertical"
      className="addProducts"
      autoCorrect="on"
      autoComplete="on"
      onFinish={onFinished}
      name="products"
    >
      <Flex vertical>
          
        <Flex vertical={false} gap={57} style={{ marginTop: "1.5rem" }}>
          <Flex vertical gap={15} style={{width: '50%'}}>
            <Form.Item
              label="Product Name"
              name="name"
              rules={[
                { required: true, message: "Please provide product's name" },
              ]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Input className="form-put" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Please provide description" },
              ]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Input className="form-put" />
            </Form.Item>
            <Form.Item
              label="Categories"
              name="categories"
              rules={[
                { required: true, message: "input category product belongs" },
              ]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Select
                defaultValue="Categories"
                style={{
                  height: '2.7rem',
                }}
                onChange={handleChange}
                className="form-select"
              >
                {categories.map((category) => (
                  <Select.Option key={category._id} value={category._id} style={{width:'30rem'}}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Price"
              name="price"
              rules={[{ required: true, message: "Please enter price" }]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Input className="form-put" />
            </Form.Item>
            <Form.Item
              label="Points"
              name="points"
              rules={[{ required: true, message: "Please enter price" }]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Input className="form-put" />
            </Form.Item>
            <Uploader 
          setFileUrl={(url) => setImagesUrl([...images, url]) }
          accept='images/*'
          multiple={true}
          maxCount={10}
          />
          </Flex>
          <Flex vertical gap={15} style={{width: '50%'}}>
          <Form.Item
              label="UPC"
              name="upc"
              rules={[{ required: true, message: "Enter a unique upc" }]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Input className="form-put" type="number"/>
            </Form.Item>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[{ required: true, message: "how many do you want" }]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Input className="form-put" />
            </Form.Item>
            <Form.Item
              label="Subtext"
              name="tag"
              rules={[{ required: true, message: "you can input a subtext" }]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Select
                defaultValue="Subtexts"
                style={{
                  height: '2.7rem',
                }}
                onChange={handleChange}
                className="form-select"
              >
                {subtexts.map((subtext) => (
                  <Select.Option key={subtext._id} value={subtext._id} style={{width:'30rem'}}>
                    {subtext.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Active"
              name="active"
              rules={[{ required: true, message: " " }]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Select
              className="form-select"
                defaultValue="Active"
                style={{
                  height: '2.7rem',
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "true",
                    label: "True",
                  },
                  {
                    value: "false",
                    label: "False",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Locations"
              name="locationId"
              rules={[{ required: true, message: "Please provide location" }]}
              className="form-item"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: "-.2rem" },
              }}
            >
              <Select
                defaultValue="Locations"
                style={{
                  
                  height: '2.7rem',
                  
                }}
                onChange={handleChange}
                className="form-select"
              >
                {locations.map((location) => (
                  <Select.Option key={location._id} value={location._id}>
                    {location.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
           
          </Flex>
        </Flex>
        <Flex >
          <Form.Item>
            <Button htmlType="submit" block className="suBut" loading={loading}>
              Submit
            </Button>
          </Form.Item>
        </Flex>
      </Flex>
    </Form>
  );
};

export default AddProduct;
