import React, { useEffect, useState } from "react";
import HeaderThree from "../../components/Tags/Headers/HeaderThree";
import HeaderSix from "../../components/Tags/Headers/HeaderSix";
import { Col, Row, Dropdown, Space, List, message, Table, notification} from "antd";
import OrderChart from "../../components/OrderChart/OrderChart";
import EarningChart from "../../components/EarningChart/EarningChart";
import { DownOutlined } from "@ant-design/icons";
import VirtualList from "rc-virtual-list";
import Icon from "../../components/Tags/Icon";
import AxiosCall from "../../App/config/axios";

const Overview = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [orders, setOrders] = useState()

  useEffect(() => {
    appendData();
    loadMoreData();
    getOrders()
  }, []);

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };

  const getOrders = async (page = 1) => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "order",
        method: "GET",
        // params: {page: page, limit: 8},
      });
      const data = response;
      notificationWithIcon("success", "all products", response.message);
      setLoading(false);
      console.log(response.data);
      setOrders(response.data);
      return data;
    } catch (error) {
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    fetch('https://randomuser.me/api/?results=10&inc=name,gender,email,nat,picture&noinfo')
      .then((res) => res.json())
      .then((body) => {
        setDatas([...data, ...body.results]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fakeDataUrl = "https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo";


  const appendData = () => {
    fetch(fakeDataUrl)
      .then((res) => res.json())
      .then((body) => {
        setData(data.concat(body.results));
        // message.success(`${body.results.length} more items loaded!`);
      });
  };

  const onScroll = (e) => {
    // Refer to: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#problems_and_solutions
    if (
      Math.abs(
        e.currentTarget.scrollHeight -
        e.currentTarget.scrollTop -
        ContainerHeight
      ) <= 1
    ) {
      appendData();
    }
  };

  const ContainerHeight = 330;



  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          Monday
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          Tuesday
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          Wednesday
        </a>
      ),
    },
    {
      key: "4",
      danger: false,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          Thursday
        </a>
      ),
    },
    {
      key: "5",
      danger: false,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          Friday
        </a>
      ),
    },
    {
      key: "6",
      danger: false,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          Saturday
        </a>
      ),
    },
    {
      key: "7",
      danger: false,
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          Sunday
        </a>
      ),
    },
  ];

  const columns = [
    {
      title: 'Products',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Price',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Customer name',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Points',
      dataIndex: 'point',
      key: 'point',
    },
  ];
  const fillers = [
    {
      key: '1',
      name: <fieldset className="name"> <Icon url='/images/Rectangle 39680.svg' />John Brown</fieldset>,
      age: <h4 className="price">$32</h4>,
      address: <h4 className="price">Smith James</h4>,
      point: <fieldset className="point"> <Icon url='/images/Group 1000004938.svg' />250 Points</fieldset>,
    },
    {
      key: '2',
      name: <fieldset className="name"> <Icon url='/images/Rectangle 39680 (1).svg' />Jim Green</fieldset>,
      age: <h4 className="price">$42</h4>,
      address: <h4 className="price">Adam Ibrahim</h4>,
      point: <fieldset className="point"> <Icon url='/images/Group 1000004938.svg' />321 Points</fieldset>,
    },
    {
      key: '3',
      name: <fieldset className="name"> <Icon url='/images/Rectangle 39680 (3).svg' />Joe Black</fieldset>,
      age: <h4 className="price">$32</h4>,
      address: <h4 className="price">Kenneth Cole</h4>,
      point: <fieldset className="point"> <Icon url='/images/Group 1000004938.svg' />65 Points</fieldset>,
    },
  ];

  return (
    <Row className="overview">
      <Col className="overview-col-A" span={16.5}>
        <div className="overview-analytics">
          <fieldset className="overview-fieldset-A">
            <HeaderSix text="Total Sales" customClasses="overview-h6" />
            <HeaderThree text="$0.00" customClasses="overview-h3" />
          </fieldset>
          <fieldset className="overview-fieldset-B">
            <HeaderSix text="Total Products" customClasses="overview-h6" />
            <HeaderThree text="0" customClasses="overview-h3" />
          </fieldset>
          <fieldset className="overview-fieldset-C">
            <HeaderSix text="Total Orders" customClasses="overview-h6" />
            <HeaderThree text="0" customClasses="overview-h3" />
          </fieldset>
        </div>
        <EarningChart />
        <div className="overview-table">
          <fieldset className="sale-table">
            <HeaderSix
              text="Top Selling Products"
              customClasses="sale-header"
            />
            <Dropdown
              menu={{
                items,
              }}
              className="chart-dropdown"
            >
              <a onClick={(er) => er.preventDefault()}>
                <Space>
                  This week
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </fieldset>
          <div
            id="scrollableDiv"
            style={{
              height: 200,
              overflow: 'auto',
              padding: '0 16px',
              // border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
          >
            <Table
              size="small"
              columns={columns}
              dataSource={fillers}
              pagination={false}
              className="table"
            />
          </div>
        </div>
      </Col>
      <Col span={7} className="overview-col-B">
        <OrderChart />
        <div className="recent-order">
          <fieldset className="orderchart-fielset">
            <HeaderSix text="Recent Orders" customClasses="orderchart-header" />
            <fieldset className="three-dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </fieldset>
          </fieldset>
          <List className="order-list" size="small">
            <VirtualList
              data={data}
              height={ContainerHeight}
              itemHeight={47}
              itemKey="email"
              onScroll={onScroll}
            >
              {(item) => (
                <List.Item key={item.email}>
                  <List.Item.Meta
                    avatar={<Icon url={item.picture.large} customClasses='list-picture' />}
                    title={<a href="https://ant.design">{item.name.last}</a>}
                    description={item.email}
                  />
                  <div>$45</div>
                </List.Item>
              )}
            </VirtualList>
          </List>
        </div>
      </Col>
    </Row>
  );
};

export default Overview;
