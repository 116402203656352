import {configureStore} from '@reduxjs/toolkit'
import {combineReducers} from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './features/user/userSlice';

const rootReducer = combineReducers({
    userReducer,
})


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  export const store = configureStore({
    reducer: persistedReducer
  });
// export const persistor = persistStore(store);
export default store;