import React, {useState, useEffect, useRef} from 'react'
// import HeaderThree from '../../components/Tags/Headers/HeaderThree'
import Icon from '../../components/Tags/Icon'
import {Row, Col, Input, Table, Button, Form, Modal, notification,  Alert, Typography} from "antd"
import AxiosCall from '../../App/config/axios'
import { SearchOutlined } from "@ant-design/icons";
import "antd/dist/reset.css";

const {Title} = Typography;

const Locations = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const [button, setButton] = useState(false);
  const [active, setActive] = useState(true);
const [locations, setLocations] = useState([]);
 const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentEdit, setCurrentEdit] = useState(null);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [showLocationData, setShowLocationData] = useState({});
  const [showBankDetails, setShowBankDetails] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [confirmer, setConfirmer] = useState(false);

  const former = useRef(null)

  useEffect(() => {
    getLocations()
  }, [])
  

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };

  const getLocations = async (page = 1) => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "location/getlocations",
        method: "GET",
        params: {page: page, limit: 8},
      });
      const data = response;
      notificationWithIcon("success", "all locations", response.message);
      setLoading(false);
      console.log(response);
      setLocations(response.data);
      return data;
    } catch (error) {
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setShowBankDetails(false);
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const onFinish = async (values) => {
    setError("");
    setLoading(true);
    // navigate('/dashboard/products')
    try {
      const response = await AxiosCall({
        path: "location/createlocation,",
        method: "POST",
        data: { ...values },
      });
      notificationWithIcon(
        "success",
        "location successfully added",
        response.message
      );
      setError("");
      setLoading(false);
    } catch (error) { 
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };


  const openEditLocation = (currentEdit) => {
    setCurrentEdit(currentEdit);
    setIsModalVisibleEdit(true);
  };
  const closeModalEdit = () => {
    setIsModalVisibleEdit(false);
    setCurrentEdit(null);
    setShowBankDetails(false);
  };

  const onFinishEdit = async (values) => {
    setError("");
    setLoading(true);
    try {
        console.log(values);
      const response = await AxiosCall({
        path: "location/updatelocation",
        method: "PATCH",
        data: { ...values },
      });
      console.log(response);
      notificationWithIcon(
        "success",
        "location successfully edited",
        response.message
      );
      setError("");
      setLoading(false);
    } catch (error) {
        console.log(error);
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const columns = [
    {
      title: (
        <Title level={1} className="custo">
          SN
        </Title>
      ),
      width: 60,
      dataIndex: "sn",
      key: "name",
      fixed: "left",
      className: "headera",
    },
    {
      title: (
        <Title level={1} className="custo">
          Name
        </Title>
      ),
      width: 220,
      dataIndex: "name",
      key: "age",
      fixed: "left",
      className: "headera",
    },
    {
      title: (
        <Title level={1} className="custo">
          Address
        </Title>
      ),
      dataIndex: "address",
      key: "1",
      width: 490,
    },
    {
      title: (
        <Title level={1} className="custo">
          City
        </Title>
      ),
      dataIndex: "city",
      key: "2",
      width: 130,
    },
    {
      title: (
        <Title level={1} className="custo">
          State
        </Title>
      ),
      dataIndex: "state",
      key: "3",
      width: 130,
    },
    {
      title: (
        <Title level={1} className="custo">
          ZipCode
        </Title>
      ),
      dataIndex: "zipcode",
      key: "zipcode",
      width: 110,
    },
    {
      title: (
        <Title level={1} className="custo">
          Action
        </Title>
      ),
      key: "operation",
      fixed: "left",
      width: 50,
      render: (record) => (
        
        <span className="all-buttons">
          
          <Button
          type='primary'
            className="button-1"
            onClick={() => {
              setShowLocation(true);
              setShowLocationData(record);
            }}
            style={{backgroundColor:'#2072BF'}}
          >
            <Icon url="/images/Group.svg" />
            <span>View</span>
          </Button>
          
            <Button
            type='primary'
              onClick={() => openEditLocation(record)}
              className="button-2"
              style={{backgroundColor:'#FEB721'}}
            >
              <Icon url="/images/editer.svg" />
              <span>Edit</span>
            </Button>
          {/* <span style={{ width: "7rem" }}>
                <Button
                  className="button-4"
                  loading={loading}
                  disabled={loading}
                //   onClick={() => deactivateLocation(record._id)}
                  // onClick={activate}
                >
                  <Icon url="/images/arrow-down.svg" />
                  <span>Deactivate</span>
                </Button>
                <Button
                  className="button-3"
                  loading={loading}
                  disabled={loading}
                //   onClick={() => activateLocation(record._id)}
                  // onClick={deactivate}
                >
                  <Icon url="/images/arrow-up.svg" />
                  <span>Activate</span>
                </Button>
          </span> */}
        </span>
      ),
    },
  ];

  const onSearch = (value, _e, info) => console.log(info?.source, value);
  
  return (
    <Row className="locations">
       <Col>
          {/* <HeaderThree text="Locations" customClasses="h-header" /> */}
          <section className="add-locations">
            <Input
              placeholder="Search location"
              className="inputAndIcon"
              suffix={<SearchOutlined style={{color: '#9FA2BF'}} onClick={{ onSearch }} />}
            />
              <Button
                
                // loading={props.loading}
                // disabled={props.loading}
                onClick={() => openModal()}
                className="addLocation"
              >
                Add Location
              </Button>
            
          </section>
      </Col>
       <Col>
          <div className="location-table">
            <Table
            pagination={false}
              className="table-row"
              columns={columns}
              dataSource={locations}
            //   pagination={{
            //     pageSize: 50,
            //   }}
            //   scroll={{
            //     y: 240,
            //   }}
              rowClassName="rowClassName"
              size="small"
              bordered={false}
              loading={loading}
              rowKey={(record) => record._id}
            />
          </div>       
       </Col>
       <Modal
          title="Add Entry"
          className="vendorShadow"
          centered
          width="27.80588rem"
          height="41.7rem"
          open={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
          footer={null}
        >
          {error && (
            <Alert
              message={error}
              type="error"
              closable
              onClose
              style={{ marginBottom: "2rem" }}
            />
          )}
          <Form
            ref={former}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            autoCorrect="on"
            name="login"
          >
            <Form.Item
              className="location-form"
              label="Name"
              name="name"
              rules={[{ required: true, message: "Please input your name" }]}
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: ".5rem" },
              }}
            >
              <Input className="location-input" />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[
                { required: true, message: "Please provide your address" },
              ]}
              className="location-form"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: ".5rem" },
              }}
            >
              <Input className="location-input" />
            </Form.Item>
            <Form.Item
              label="City"
              name="city"
              className="location-form"
              rules={[{ required: true, message: "Please provide your City" }]}
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: ".5rem" },
              }}
            >
              <Input className="location-input" />
            </Form.Item>
            <Form.Item
              label="State"
              name="state"
              className="location-form"
              rules={[{ required: true, message: "No State has been input" }]}
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: ".5rem" },
              }}
            >
              <Input className="location-input" />
            </Form.Item>
            <Form.Item
              label="Zipcode"
              name="zipcode"
              rules={[
                {
                  required: true,
                  message: "Please provide the city's zipcode",
                },
              ]}
              className="location-form"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: ".5rem" },
              }}
            >
              <Input className="location-input" />
            </Form.Item>
            <Form.Item
              label="Store Phone Number"
              name="storePhoneNumber"
              rules={[
                {
                  required: true,
                  message: "You have not provided the store phone number",
                },
              ]}
              className="location-form"
              labelCol={{
                style: { marginTop: "-.5rem", marginBottom: ".5rem" },
              }}
            >
              <Input className="location-input" />
            </Form.Item>
            <Form.Item
                  label="Company Name"
                  name="companyName"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Company Name" className="location-input"/>
                </Form.Item>
              <div style={{display: showBankDetails ? 'block' : 'none'}}>
              <Form.Item
                  label="Bank Name"
                  name="bankName"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank Name" className="location-input"/>
                </Form.Item>

                <Form.Item
                  label="Bank Address"
                  name="bankAddress"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank Address" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Bank CIty"
                  name="bankCity"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank City" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Bank State"
                  name="bankState"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank State" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Bank Zipcode"
                  name="bankZipcode"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank Zipcode" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name="accountNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Account Number" className="location-input"/>
                </Form.Item>

                <Form.Item
                  label="Routing Number"
                  name="routingNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Routing Number" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Starting Check Number"
                  name="startingCheckNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder=" Starting Check Number" className="location-input"/>
                </Form.Item>

                <Form.Item
                  label="Bank Phone Number"
                  name="bankPhoneNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank Phone Number" className="location-input"/>
                </Form.Item>
              </div>
             {!showBankDetails && <Button className="more-btn" type="link" onClick={()=> setConfirmation(true)}> See more  ...</Button>}
            <Form.Item>
              <Button
                htmlType="submit"
                onClick={closeModal}
                className="submitBut"
                disabled={loading}
                loading={loading}
                style={{backgroundColor:'#2072BF'}}
                type='primary'
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
       </Modal>
       <Modal
            className="vendorShadow"
              title="Edit Entry"
              open={isModalVisibleEdit}
              onOk={closeModalEdit}
              onCancel={closeModalEdit}
              footer={null}
              maskStyle={{ backgroundColor: "rgba(10, 22, 35, 7%)" }}
              width="27.80588rem"
              height="41.7rem"
              centered
            >

              <Form
                initialValues={{
                //   name: currentEdit.name,
                //   state: currentEdit.state,
                //   address: currentEdit.address,
                //   zipcode: currentEdit.zipcode,
                //   permissionLevel: currentEdit.permissionLevel,
                //   city: currentEdit.city,
                //   companyName: currentEdit.companyName,
                //   bankName: currentEdit.bankName,
                //   bankAddress: currentEdit.bankAddress,
                //   bankCity: currentEdit.bankCity,
                //   bankState: currentEdit.bankState,
                //   bankZipcode: currentEdit.bankZipcode,
                //   accountNumber: currentEdit.accountNumber,
                //   routingNumber: currentEdit.routingNumber,
                //   startingCheckNumber: currentEdit.startingCheckNumber,
                //   storePhoneNumber: currentEdit.storePhoneNumber,
                //   bankPhoneNumber: currentEdit.bankPhoneNumber,
                }}
                name="login"
                layout="vertical"
                onFinish={onFinishEdit}
              >
                <Form.Item
                  label="Name"
                  name="name"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                  className="location-form"
                >
                  <Input
                    placeholder="Name"                    
                    className="location-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Address"
                  name="address"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input
                    placeholder="Address"                    
                    className="location-input"
                  />
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input
                    placeholder="City"                    
                    className="location-input"
                  />
                </Form.Item>
                <Form.Item
                  label="State"
                  name="state"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input
                    placeholder="State"                   
                    className="location-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Zipcode"
                  name="zipcode"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input
                    placeholder="Zipcode"                   
                    className="location-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Store Phone Number"
                  name="storePhoneNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input
                    placeholder="Store Phone Number"                   
                    className="location-input"
                  />
                </Form.Item>
                <div style={{display: showBankDetails ? 'block' : 'none'}}>

                  <Form.Item
                  label="Bank Name"
                  name="bankName"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                  >
                  <Input placeholder="Bank Name" className="location-input"/>
                </Form.Item>

                <Form.Item
                  label="Bank Address"
                  name="bankAddress"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  
                  <Input placeholder="Bank Address" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Bank CIty"
                  name="bankCity"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank City" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Bank State"
                  name="bankState"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank State" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Bank Zipcode"
                  name="bankZipcode"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank Zipcode" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Account Number"
                  name="accountNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Account Number" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Routing Number"
                  name="routingNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Routing Number" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Starting Check Number"
                  name="startingCheckNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder=" Starting Check Number" className="location-input"/>
                </Form.Item>
                <Form.Item
                  label="Bank Phone Number"
                  name="bankPhoneNumber"
                  className="location-form"
                  labelCol={{
                    style: { marginTop: "-.5rem", marginBottom: ".5rem" },
                  }}
                >
                  <Input placeholder="Bank Phone Number" className="location-input"/>
                </Form.Item>
                  </div>
                {!showBankDetails && <Button className="more-btn" type="link" onClick={()=> setConfirmer(true)}>See more ...</Button>}
                <Form.Item>
                  <Button
                    htmlType="submit"
                    // onClick={closeModalEdit}
                    className="submitBut"
                    disabled={loading}
                    loading={loading}
                    style={{backgroundColor:'#2072BF'}}
                    type='primary'
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
       </Modal>
    </Row>
  );
  }

export default Locations