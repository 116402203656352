import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  List,
  notification,
  Modal,
  Form,
  Button,
  Input,
} from "antd";
import HeaderThree from "../../components/Tags/Headers/HeaderThree";
import { Link } from "react-router-dom";
import AxiosCall from "../../App/config/axios";
import ModalUploader from "../../components/Upload/ModalUploader";
// import Icon from "../../components/Tags/Icon";

const { Meta } = Card;

const SubText = () => {
  const [loading, setLoading] = useState(false);
  const [subtexts, setSubtexts] = useState({docs:[]});
  const [error, setError] = useState(" ");
  const [isModalVisible, setisModalVisible] = useState(false);
  const [imageUrl, setImagesUrl] = useState("");
  
  useEffect(() => {
    getSubTexts();
  }, []);

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };

  const getSubTexts = async (page = 1) => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "tag",
        method: "GET",
        params: {
            page: page,
            limit: 10,
        }
      });
      const data = response;
      notificationWithIcon("success", "all subtexts", response.message);
      setLoading(false);
      console.log(response.data);
      setSubtexts(response.data);
      return data
    } catch (error) {
      console.log(error);
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const onFinish = async (values) => {
    setError("");
    setLoading(true);
    try {
    //   console.log(values);
    //   console.log(imageUrl);
      const response = await AxiosCall({
        path: "tag",
        method: "POST",
        data: { ...values, imageUrl },
      });
      console.log(response);
      notificationWithIcon("success", "Successful", response.message);
      setError("");
      setLoading(false);
      getSubTexts();
    } catch (error) {
      console.log(error);
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Row className="subtext">
      <Col>
        <div className="top-pane">
          <Link className="subtext" to="/dashboard/SubText">
            <Button type="primary" style={{backgroundColor:'#2072BF'}} className="sub" onClick={() => setisModalVisible(true)}>
             Create Subtext
            </Button>
          </Link>
        </div>
      </Col>
      <Col className="product-list">
        <List
          className="flewRap"
          grid={{
            gutter: 24,
            column: 5,
          }}
            pagination={{
                onChange: (page) => {
                    getSubTexts(page)
                    console.log(page);
                  },
              pageSize: 10,
              total:subtexts.totalPages
            }}
          itemLayout="vertical"
          loading={loading}
          dataSource={subtexts.docs}
          renderItem={(subtext) => (
            <List.Item key={subtext._id}>
              <Link to={`/dashboard/${subtext._id}`}>
                <Card
                  size="small"
                  cover={
                    <img
                      key={subtext._id}
                      alt="example"
                      src={subtext.imageUrl}
                      className="card-image"
                    />
                  }
                  className="card"
                  key={subtext._id}
                >
                  <Meta title={subtext.name} className="meta" />
                </Card>
              </Link>
            </List.Item>
          )}
        />
      </Col>
      <Modal
        className="subadmin-style"
        title="Create Subtext"
        open={isModalVisible}
        onOk={() => setisModalVisible(false)}
        onCancel={() => setisModalVisible(false)}
        footer={null}
        centered
        width="21.80588rem"
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoCorrect="on"
          autoComplete="on"
          name="subtexts"
        >
          <Form.Item
            label="Subtext"
            name="name"
            rules={[
              {
                required: true,
                message: "Input is required",
              },
            ]}
            labelCol={{
              style: { marginTop: "1.5rem", marginBottom: "-.3rem" },
            }}
            className="subadmin-form"
          >
            <Input type="text" className="subadmin-input" />
          </Form.Item>
          <ModalUploader
            setFileUrl={(url) => setImagesUrl(...imageUrl, url)}
            accept="images/*"
            multiple={false}
            maxCount={10}
          />
          <Form.Item>
            <Button
              htmlType="submit"
              className="subBut"
              disabled={loading}
              loading={loading}
              onClick={() => setisModalVisible(false)}
              style={{backgroundColor:'#2072BF'}}
              type="primary"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default SubText;