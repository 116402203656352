import React, { useState, useRef } from "react";
import { notification, Upload } from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";



const Uploader = ({setFileUrl, accept, maxCount, multiple = false, defaultFileList }) => {
    const [fileList, setfileList] = useState([])
    const [uploading, setuploading] = useState(false)
    const [loading, setloading] = useState(false)

    const form = useRef(null)
    
    const openNotificationWithIcon = (type, msg, desc) => {
        notification[type]({
          message: msg,
          description: desc,
        });
      };

    const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
        
      };
    
      const handleChange = (info) => {
        let fileList = [...info.fileList];
        fileList = fileList.map((file) => {
          if (file.response) {
            file.url = file.response.url;
          }
          return file;
        });
        setfileList(fileList);
        if (info.file.status === "uploading") {
          setuploading(true);
        } else {
          setuploading(false);
          info.file.status !== 'error' && setFileUrl(info?.file?.response?.url  ? info.file.response.url : '')
        }
      };
      console.log(defaultFileList);

      const checkForDefault = () => {
        let forDefault = [];
        if (defaultFileList) {
          defaultFileList.map((url) => {
            forDefault.push({url, uid: url, status:'done', name: url})
          })
        }
        console.log(forDefault, 'where are you');
        return forDefault;
        
      }

      return (
        <Upload 
        fileList={fileList }
        defaultFileList={checkForDefault()}
        listType="picture"
          accept={accept ? accept : ".doc, .docx, .pdf"}
        action="https://byzpal.com/api/application/uploadfile"
        onChange={handleChange}
        showUploadList={true}
        maxCount={maxCount ? maxCount : 1}
        multiple={multiple}
        className="upload"
        >
           <div className="div">
              <p className="icon">
                <UploadOutlined className="uploader"/>
              </p>
              <p className="worder">
                Click or drag file to this area to upload images
              </p>
           </div>
        </Upload>
    );
}

export default Uploader;