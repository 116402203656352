import React from "react";
import Chart from "react-apexcharts";
import HeaderSix from "../Tags/Headers/HeaderSix";
import Icon from "../Tags/Icon";

const OrderChart = () => {
  const state = {
    series: [40, 60],
    labels: ["Delivery", "Store Pickup"],
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "46%",
        },
      },
    },
    colors: ["#2E2E2E", "#DBF3FF"],
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val) {
        return val + "%";
      },
      style: {
        fontSize: ".875rem",
        // fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: "bold",
      },
    },
    legend: {
        position: 'bottom',
        inverseOrder: true,
        width: 300,
        fontWeight: 700,
        fontSize: '14px',
        horizontalAlign: 'left', 
        markers: {
          width: 12,
          height: 12,
          radius: 0,
        },
        itemMargin: {
            horizontal: 20,
            vertical: 0,
        },
    },
    tooltip: {
      fillSeriesColor: true,
    },
    fill: {
      colors: ["#2E2E2E", "#DBF3FF"],
    },
    
  };

  return (
    <div className="order-chart">
        <fieldset className="orderchart-fielset">
          {/* <div className="chart-div"> */}
          <HeaderSix text="Mode of Order" customClasses="orderchart-header" />
            <fieldset className="three-dots">
              <span className="dot"></span>
              <span className="dot"></span>
              <span className="dot"></span>
            </fieldset>
          {/* </div> */}
            
          </fieldset>
      <Chart height={250} options={state} series={state.series} type="donut" width="267"/>
    </div>
  );
};

export default OrderChart;
