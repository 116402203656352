import React, { useRef, useState } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import HeaderOne from "../../components/Tags/Headers/HeaderOne";
import HeaderThree from "../../components/Tags/Headers/HeaderThree";
import { useDispatch, useSelector } from "react-redux";
import { logInUser } from "../../App/features/user/userSlice";
import AxiosCall from "../../App/config/axios";
import App from "../../App";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  // const isLoggedIn = useSelector((state) => state.userReducer.isLoggedIn);
  // console.log(isLoggedIn);
  const form = useRef();

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };

  const onFinished = async (values) => {
    try {
      setError("");
      setLoading(true);
      const response = await AxiosCall({
        path: "users/signin",
        method: "POST",
        data: { ...values },
      });
      dispatch(
        logInUser({
          user: response.user,
          token: response.token,
        })
      );
      window.location.assign('/dashboard')
      notificationWithIcon("success", "user logged in", response.message);
    } catch (e) {
      let errorResponse = "Something went wrong. please try again";
      if (e?.response) {
        const { error } = e.response.data;
        errorResponse = error;
      }
      setLoading(false);
      setError(errorResponse);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const onFinishedFailed = () => {
    setError("");
    setLoading(false);
  };

  return (
    // <>
    //   {!isLoggedIn ? (
        <Row className="main">
          <Col xl={12} sm={12} xs={0} md={12} lg={12} xxl={12}>
            <div className="login-image"></div>
          </Col>
          <Col
            xl={12}
            sm={12}
            xs={24}
            md={12}
            lg={12}
            xxl={12}
            className="down-col"
          >
            <div className="form-col">
              <HeaderOne text="Login" customClasses="login-header" />
              <Form
                layout="vertical"
                ref={form}
                name="login"
                onFinish={onFinished}
                onFinishFailed={onFinishedFailed}
              >
                <Form.Item
                  className="login-form"
                  label="Email Address"
                  name="email"
                  labelCol={{
                    style: {
                      marginTop: "1rem",
                      marginBottom: "-.1rem",
                    },
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your email",
                    },
                    {
                      type: "email",
                      message: "wrong email input",
                    },
                  ]}
                >
                  <Input
                    className="login-input"
                    placeholder="Enter your email"
                  />
                </Form.Item>
                <Form.Item
                  className="login-password-form"
                  label="Password"
                  name="password"
                  labelCol={{
                    style: {
                      marginBottom: "-.1rem",
                    },
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password",
                    },
                  ]}
                >
                  <Input.Password
                    className="login-password-input"
                    placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                  />
                </Form.Item>
                <HeaderThree text="Forgot Password?" customClasses="forgot" />
                <Form.Item className="form-login-button">
                  <Button
                    className="form-button"
                    loading={loading}
                    htmlType="submit"
                  >
                    Login
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      // ) : (
        // <App />
      )
    // }
    // </>
  // );
};

export default Login;
