import React, { useEffect, useState } from "react";
import { Row, Col, Input, Card, List, notification, } from "antd";
import Icon from "../../components/Tags/Icon";
import HeaderThree from "../../components/Tags/Headers/HeaderThree";
import { SearchOutlined } from "@ant-design/icons";
import HeaderSix from "../../components/Tags/Headers/HeaderSix";
import { Link } from "react-router-dom";
import AxiosCall from "../../App/config/axios";

const { Meta } = Card;

const onSearch = (value, _e, info) => console.log(info?.source, value);

const Products = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({docs: []});
  const [currentpage, setcurrentpage] = useState(1)
 
  console.log(products);

  useEffect(() => {
    getProducts();
  }, []);

  const handleProductDelete = async (productId) => {
    const confirm = window.confirm("would you like to delete?");
    if (confirm) {
      setLoading(true);
      try {
        const response = await AxiosCall({
          path: "item",
          method: "DELETE",
          data: { productId },
        });
        console.log(response);
        notificationWithIcon("success", "product deletion", response.message);
        getProducts()
        setLoading(false);
      } catch (error) {
        let errorResponse = "Something went wrong. please try again";
        if (error?.response) {
          const { err } = error.response.data;
          errorResponse = err;
        }
        notificationWithIcon(
          "error",
          "Error",
          errorResponse || "Something went wrong"
        );
      }
    }
  };

  const notificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description: desc,
    });
  };

  const getProducts = async (page = 1) => {
    setLoading(true);
    try {
      const response = await AxiosCall({
        path: "item",
        method: "GET",
        params: {page: page, limit: 8},
      });
      const data = response;
      notificationWithIcon("success", "all products", response.message);
      setLoading(false);
      console.log(response.data);
      setProducts(response.data);
      return data;
    } catch (error) {
      let errorResponse = "Something went wrong. please try again";
      if (error?.response) {
        const { err } = error.response.data;
        errorResponse = err;
      }
      setLoading(false);
      notificationWithIcon(
        "error",
        "Error",
        errorResponse || "Something went wrong"
      );
    }
  };

  const itemRender = (_, type, page, originalElement) => {
    if (type === 'prev') {
      return <div className="previous"><Icon url={'/images/backarrow.svg'}/>Previous</div>;
    }
    if (type === 'next') {
      return <div className="next">Next <Icon url={'/images/forwardarrow.svg'}/></div>;
    }
    if (type === 'page') {
      return <div className="page">{page}</div>;
    }
    return originalElement;
  }

  const customStyle = {
    // display:"flex",
    // 'align-self':'flex-start',
    // 'justify-self':'flex-start',
    // backgroundColor: "lightblue",
    // color: "darkblue",
    // border: "1px solid blue",
    // padding: "8px",
  };
  
  return (
    <Row className="products">
      <Col>
        <div className="top-pane">
          <div className="counter">
            <fieldset className="product-count">
              <HeaderThree text="Products:" customClasses="sub" />
              <span className="state">{products?.totalDocs || 0
}</span>
            </fieldset>
            <Input
              prefix={<SearchOutlined onClick={{ onSearch }} />}
              placeholder="Search here..."
              className="nav-search"
            />
            <fieldse className="filter">
              <Icon url="/images/icon.svg" customClasses="state" />
              <HeaderSix text="Filter" customClasses="state" />
            </fieldse>
          </div>
          <div className="plus">
            <fieldset className="highlight">
              <Icon url="/images/Container.svg" customClasses="icon-a" />
              <Icon url="/images/Container2.svg" customClasses="icon" />
            </fieldset>
            <Link className="subtext" to="/dashboard/SubText">
              <HeaderThree text="View subtext" customClasses="sub" />
            </Link>
            <Link className="add" to="/dashboard/AddProduct">
              <Icon url="/images/plus-circle.svg" customClasses="state" />
              <HeaderThree text="Add Products" customClasses="sub" />
            </Link>
          </div>
        </div>
      </Col>
      <Col className="product-list">
        <List
          className="flewRap"
          
          children={<div className="pageOf">Page {currentpage} of {products.totalPages}</div>}
          grid={{
            gutter: 24,
            column: 4,
          }}
          itemLayout="vertical"
          loading={loading}
          pagination={{
            onChange: (page) => {
              getProducts(page)
              console.log(page);
              setcurrentpage(page)
            },
            responsive:true,
            style:customStyle,
            // prefixCls:<div style={{color:"red"}}>err</div>,
            pageSize:8,
            total:products.totalPages,
             className:"pagination",
            selectComponentClass:'',
            // pageSizeOptions:'',
            itemRender:itemRender,
            // current:'19'
          }}
          dataSource={products.docs}
          renderItem={(product) => (
            <List.Item key={product._id}>
              <Card
                hoverable
                size="small"
                cover={product.images.map((image, dataIndex) => (
                  <img
                    key={dataIndex}
                    alt="example"
                    src={image}
                    className="card-image"
                  />
                ))}
                className="card"
                key={product._id}
              >
                <Meta title={product.name} className="meta" />
                <fieldset className="edit">
                  <p className="price">{"$" + product.price}</p>
                  <span className="two-icons">
                    <Link to={`/dashboard/EditProduct/${product._id}`}>
                      <Icon url="/images/edit.svg" />
                    </Link>
                    <Icon
                      url="/images/delete.svg"
                      onClickEvent={() => handleProductDelete(product._id)}
                      customClasses="delete"
                    />
                  </span>
                </fieldset>
                <div className="availability">
                  <fieldset className="stock">
                    <Icon url="/images/world.svg" />
                    <HeaderSix
                      text={`${product.quantity} in Stock`}
                      customClasses="stock-val"
                    />
                  </fieldset>
                  <fieldset className="stock">
                    <Icon url="/images/Group 1000004938.svg" />
                    <HeaderSix
                      text={`${product.points} points`}
                      customClasses="stock-val"
                    />
                  </fieldset>
                </div>
              </Card>
            </List.Item>
            
          )}
        />
        <div className="pageOf">Page {currentpage} of {products.totalPages}</div>
      </Col>
    </Row>
  );
};
export default Products;